import React, { useContext } from "react";
import { Breadcrumbs, MainLayout, Container } from "components";
import { BreadcrumbItem } from "components/UI/Breadcrumbs";
import Notifications from "../Notifications";
import { WorkspaceContext } from "components/providers/WorkspaceProvider";
import DashboardSkeleton from "../DashboardSkeleton";
import { useDashboard } from "hooks/useDashboard";
import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import { TiHome } from "react-icons/ti";
import MarketingConsentModal from "../modals/MarketingConsentModal";

interface Props {
  title?: string;
  titleTag?: string;
  children?: React.ReactNode;
  breadcrumbs?: BreadcrumbItem[];
  actions?: React.ReactNode;
  titleComponent?: React.ReactNode;
  description?: React.ReactNode;
  loading?: boolean;
}

function DashboardLayout({
  title,
  children,
  breadcrumbs = [],
  titleTag,
  titleComponent,
  description,
  actions,
  loading,
}: Props) {
  const { workspace } = useContext(WorkspaceContext);
  useDashboard();

  const pageTitle = titleTag ?? title;

  if (loading) {
    return (
      <MainLayout title={pageTitle ?? "Dashboard"} dashboardMode>
        <Container content expand>
          <Breadcrumbs
            items={[
              {
                href: "/dashboard",
                label: <TiHome size={16} />,
                active: breadcrumbs.length === 0,
              },
              // ...breadcrumbs,
            ]}
          />
          <div className="main-content" style={{ marginTop: "0px" }}>
            <DashboardSkeleton />
          </div>
        </Container>
      </MainLayout>
    );
  }

  return (
    <MainLayout title={pageTitle ?? "Dashboard"} dashboardMode>
      <Container content expand>
        {workspace && <Notifications workspaceId={workspace} />}
        <Breadcrumbs
          items={[
            {
              href: "/dashboard",
              label: <TiHome size={16} />,
              active: breadcrumbs.length === 0,
            },
            ...breadcrumbs,
          ]}
        />
        <div className="wrapper-header">
          <div className="row">
            <div className="title-wrapper">
              <h4 className="title">{title}</h4>
              {titleComponent && titleComponent}
            </div>
            {actions && <div className="actions">{actions}</div>}
          </div>
          {description && <div className="description">{description}</div>}
        </div>
        <div className="main-content" data-testid="dashboard-content">
          <MarketingConsentModal />
          {children}
        </div>
      </Container>
      <style jsx>{`
        .wrapper-header {
          display: flex;
          flex-direction: column;
          margin-bottom: ${titleComponent ? "12px" : "24px"};
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }

        .title-wrapper {
          display: flex;
          align-items: center;
          height: 28px;
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }

        .title {
          font-size: ${FONT_SIZE.XL};
          font-weight: 500;
          margin-bottom: 0px;
        }

        .description {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          /* max-width: max(70%, 300px); */
          max-width: 80%;
          margin-top: 8px;
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .wrapper-header {
            flex-direction: column;
          }
        }
      `}</style>
    </MainLayout>
  );
}

export { DashboardLayout };
