import { BORDER_RADIUS, BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import React, { useEffect } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { HoverableIcon } from "../HoverableIcon";
import { IoClose } from "react-icons/io5";

export interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  open?: boolean;
  cancellable?: boolean;
  heading?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export const Modal = ({
  children,
  open,
  cancellable,
  heading,
  description,
  actions,
  onClose,
  ...rest
}: Props) => {
  const modalRef = React.createRef<HTMLDivElement>();

  useOnClickOutside(modalRef, () => {
    if (cancellable) {
      onClose?.();
    }
  });

  useEffect(() => {
    if (open) {
      // Prevent scrolling while modal is open
      document.body.style.overflow = "hidden";
    } else {
      // reset scroll behavior
      document.body.style.overflow = "auto";
    }

    return () => {
      // reset scroll behavior on unmount
      document.body.style.overflow = "auto";
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <>
      <style jsx>{`
        .backdrop {
          background-color: rgba(0, 0, 0, 0.3);
          width: 100vw;
          height: 100%;
          z-index: 9000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: fixed;
        }

        .modal-wrapper {
          position: fixed;
          max-width: 860px;
          min-width: 480px;
          max-height: 90vh;
          /* allow scrolling on overflow */
          overflow-y: auto;
          /* above sticky navbar */
          z-index: 10000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .modal {
          display: flex;
          flex-direction: column;
          background: ${COLOR.BG_WHITE};
          z-index: 10;
          border-radius: ${BORDER_RADIUS.LG};
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        }

        .modal-content {
          padding: 8px 24px 24px 24px;
          position: relative;
          overflow-y: auto;
        }

        .modal-header {
          display: flex;
          justify-content: center;
          padding: 16px 48px;
          text-transform: uppercase;
          font-size: ${FONT_SIZE.PRIMARY};
          letter-spacing: 0.5px;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
        }

        .modal-heading {
          font-size: ${FONT_SIZE.XL};
          font-weight: 500;
          text-align: center;
        }

        .modal-actions {
          display: flex;
          justify-content: flex-end;
          margin-top: 32px;
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .modal-wrapper {
            max-width: 90%;
          }
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .modal-wrapper {
            min-width: 320px;
          }
        }
      `}</style>
      <div className="backdrop"></div>
      <div className="modal-wrapper" {...rest} ref={modalRef}>
        <div className="modal">
          {heading && (
            <div className="modal-header">
              <div className="modal-heading">{heading}</div>
              {cancellable && (
                <HoverableIcon
                  icon={<IoClose size={24} />}
                  onClick={() => {
                    onClose?.();
                  }}
                  style={{ position: "absolute", right: "8px", top: "8px" }}
                />
              )}
            </div>
          )}
          <div className="modal-content">
            {description && <p className="description">{description}</p>}

            {children}

            {actions && <div className="modal-actions">{actions}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
