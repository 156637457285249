import React from "react";
import Link from "next/link";
import BigNumber from "bignumber.js";
import { getStatusColor, bytesToMB } from "utils/projects";
import { UsageProgress } from "./UsageProgress";
import { useWorkspaceQuota } from "hooks/useWorkspaceQuota";
import { routes } from "utils/routes";
import { DashboardStatsSkeleton } from "./DashboardStatsSkeleton";
import { COLOR, FONT_SIZE } from "constants/styles";
import { QUOTA_USAGE_PPU_REQUESTS_WARNING_THRESHOLD } from "constants/settings";

interface Props {
  workspaceId: string;
  isCreator: boolean;
}

function DashboardStats({ workspaceId, isCreator }: Props) {
  const { quota, isQuotaLoading, isQuotaError } =
    useWorkspaceQuota(workspaceId);
  const ipfsUsage =
    !isQuotaLoading && !isQuotaError && quota
      ? new BigNumber(quota.used.subscription.storage)
          .dividedBy(quota.quota.storageLimit)
          .multipliedBy(100)
          .toNumber()
      : 0;

  const dataAvailable = !isQuotaLoading && !isQuotaError;

  return (
    <div className="wrapper">
      {dataAvailable && quota?.ppu.active && (
        // PPU user
        <>
          <h4 className="plan-usage">
            <span className="usage-key" data-testid="plan-name">
              <Link
                href={
                  isCreator
                    ? routes.dashboardSubscription
                    : routes.workspace(workspaceId)
                }
              >
                Pay As You Go Plan
              </Link>
            </span>
            <span
              className={
                new BigNumber(quota.ppu.balance.requests).lt(
                  QUOTA_USAGE_PPU_REQUESTS_WARNING_THRESHOLD
                )
                  ? "b-text-danger"
                  : ""
              }
            >
              <UsageProgress
                current={Number(quota.ppu.balance.requests)}
                subject={"REQUESTS"}
              />
            </span>
          </h4>
        </>
      )}
      {dataAvailable && quota && !quota.ppu.active && (
        // Standard Plan user
        <>
          <h4 className="plan-usage">
            <span className="usage-key" data-testid="plan-name">
              <Link
                href={
                  isCreator
                    ? routes.dashboardSubscription
                    : routes.workspace(workspaceId)
                }
              >{`${quota.subscription.currentPlan.name} PLAN`}</Link>
            </span>
            <span
              className={
                quota.used.subscription.requests >= quota.quota.requestLimit
                  ? "b-text-danger"
                  : ""
              }
            >
              <UsageProgress
                current={quota.used.subscription.requests}
                total={quota.quota.requestLimit}
                subject={"REQUESTS"}
              />
            </span>
          </h4>
          <h4 className="plan-usage">
            <div className="ipfs-wrapper">
              <span className="usage-key">IPFS USAGE</span>
              <UsageProgress
                current={bytesToMB(quota.used.subscription.storage)}
                total={bytesToMB(quota.quota.storageLimit)}
                subject={"MB"}
              />
            </div>
          </h4>
        </>
      )}
      {!dataAvailable && <DashboardStatsSkeleton />}

      <style jsx>{`
        .wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .plan-usage {
          display: flex;
          font-size: ${FONT_SIZE.LG};
          font-family: "Rubik", sans-serif;
          font-weight: 400;
          margin: 0;
        }

        .plan-usage + .plan-usage {
          margin-top: 8px;
        }

        .usage-key {
          text-transform: uppercase;
          margin-right: 0.5rem;
        }

        .ipfs-wrapper {
          display: flex;
          align-items: center;
          color: ${getStatusColor(ipfsUsage)};
        }

        .pure-row {
          display: flex;
          justify-content: space-between;
        }

        .b-text-danger {
          color: ${COLOR.DANGER};
        }
      `}</style>
    </div>
  );
}

export { DashboardStats };
