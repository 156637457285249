import { getNetworkById } from "utils/projects";
import { useWorkspaceProjectStats } from "hooks/useWorkspaceProjectStats";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "utils/formatting";
import { Card } from "components";
import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import { WorkspaceProject } from "types/workspaces";

interface Props {
  project: WorkspaceProject;
  onClick: () => void;
}

function ProjectItem({ project, onClick }: Props) {
  const { projectStats } = useWorkspaceProjectStats({
    workspaceId: project.workspace_id,
    projectUUID: project.uuid,
  });

  const network = getNetworkById(project.network_id);

  return (
    <Card
      key={project.uuid}
      hoverable
      onClick={onClick}
      data-testid="project-item"
    >
      <div className="grid-wrapper">
        <div className="column column-name">
          <h6 className="column-header">project name</h6>
          <h5
            className="column-value"
            data-testid={`project-item-name-${project.project_name}`}
          >
            {project.project_name}
          </h5>
        </div>
        <div className="column column-api-key">
          <h6 className="column-header">API key</h6>
          <h5 className="column-value">
            {network?.projectIdPrefix}
            {project.project_id}
          </h5>
        </div>
        <div className="column column-network">
          <h6 className="column-header">Network</h6>
          <h5 className="column-value">{network?.label ?? "Unknown"}</h5>
        </div>
        <div className="column column-requests">
          <h6 className="column-header">requests today</h6>
          <h5 className="column-value">
            {projectStats?.projectRequests ? (
              formatNumber(projectStats.projectRequests)
            ) : (
              <Skeleton width="30px" />
            )}
          </h5>
        </div>
        <style jsx>{`
          .grid-wrapper {
            display: grid;
            gap: 8px 16px;
            grid-template-columns: 1fr 2fr 1fr 1fr;
            margin-top: 2px;
            width: 100%;
          }
          .column-header {
            font-size: ${FONT_SIZE.XXS};
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 600;
            color: ${COLOR.TEXT_PRIMARY};
            margin-bottom: 6px;
          }

          .column-value {
            font-family: Rubik, sans-serif;
            margin-bottom: 5px;
            font-weight: 400;
            line-height: 1.2;
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
          }

          .column {
            display: flex;
            flex-direction: column;
          }

          .column-name {
            width: 300px;
          }
          .column-network {
            min-width: 170px;
          }

          .column-api-key {
            text-overflow: ellipsis;
            overflow: hidden;
            flex: 2 1 0;
            white-space: nowrap;
          }

          .column-requests {
            align-items: flex-end;
            min-width: 150px;
            padding: 0;
          }

          @media screen and (max-width: ${BREAKPOINT.MD}px) {
            .grid-wrapper {
              gap: 16px;
              grid-template-columns: 1fr 1fr;
            }

            .column-requests {
              align-items: flex-start;
              justify-self: flex-start;
            }
          }

          @media screen and (max-width: ${BREAKPOINT.SM}px) {
            .grid-wrapper {
              gap: 16px;
              grid-template-columns: 1fr;
            }
          }
        `}</style>
      </div>
    </Card>
  );
}

export { ProjectItem };
