import React, { useState } from "react";
import { Popover, PopoverProps } from "react-tiny-popover";
import { Button } from "components";
import { FONT_SIZE, COLOR, BORDER_RADIUS } from "constants/styles";

interface Props extends Partial<PopoverProps> {
  children: PopoverProps["children"];
  disabled?: boolean;
  planName: string;
  onConfirm?: () => void;
  onReject?: () => void;
}

export const SubscriptionDowngradeConfirmation = ({
  children,
  disabled,
  planName,
  onConfirm,
  onReject,
  ...rest
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <>
      <style jsx>{`
        .confirmation-wrapper {
          max-width: 280px;
          background: ${COLOR.BG_WHITE};
          padding: 16px 24px;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          box-shadow: 0 0 25px rgb(26 108 225 / 10%);
        }

        .confirmation-title {
          font-size: ${FONT_SIZE.XXS};
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: ${COLOR.TEXT_PRIMARY};
        }

        .confirmation-body {
          font-size: ${FONT_SIZE.SECONDARY};
          font-weight: 600;
          color: ${COLOR.DANGER};
          margin-bottom: 16px;
        }

        .confirmation-actions {
          display: flex;
          flex-direction: column;
        }

        .confirmation-actions :global(button) + :global(button) {
          margin-top: 6px;
        }
      `}</style>
      <Popover
        // positions={["bottom"]}
        align="center"
        padding={10}
        onClickOutside={() => setIsPopoverOpen(false)}
        {...rest}
        isOpen={isPopoverOpen}
        content={
          <div className="confirmation-wrapper">
            <h4 className="confirmation-title">Are you sure?</h4>
            <div className="confirmation-body">
              <span>Your subscription will be downgraded to the</span>
              <span>{planName ? ` ${planName} ` : " "}</span>
              <span>
                plan with limited calls and storage, effective immediately.
              </span>
            </div>
            <div className="confirmation-actions">
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  onConfirm?.();
                  setIsPopoverOpen(false);
                }}
                data-testid="confirm-downgrade"
              >
                Yes, downgrade the subscription
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  onReject?.();
                  setIsPopoverOpen(false);
                }}
              >
                No, keep the subscription
              </Button>
            </div>
          </div>
        }
      >
        <div
          style={{ width: "100%" }}
          onClick={() => {
            if (!disabled) {
              setIsPopoverOpen(!isPopoverOpen);
            }
          }}
        >
          {children}
        </div>
      </Popover>
    </>
  );
};
