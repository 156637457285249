import React from "react";
import { toast } from "react-toastify";
import { useForm, Controller, FormProvider, FieldError } from "react-hook-form";
import { WEBHOOKS_NETWORKS } from "constants/networks";
import Select from "react-select";
import { IoHelpCircleOutline } from "react-icons/io5";
import validator from "validator";
import { ConditionsEditor } from "./ConditionsEditor/ConditionsEditor";
import {
  WebhookDefinition,
  WebhookFormData,
  WebhookFormDataRaw,
} from "types/webhooks";
import { Button, ShinyBanner, Card, Switch } from "components";
import { ERROR, MAX_CONFIRMATIONS, TRIGGER_OPTIONS } from "constants/webhooks";
import axios from "axios";
import Tippy from "@tippyjs/react";
import { WebhookIdentifierToken } from "./WebhookIdentifierToken";
import {
  getWebhookFormDefaultValues,
  transformConditionsToFormFormat,
  transformWebhookFormDataToBackendFormat,
} from "utils/webhook";
import {
  addWorkspaceWebhook,
  updateWorkspaceWebhook,
} from "hooks/useWorkspaceWebhooks";
import WorkspaceAvatar from "components/workspaces/WorkspaceAvatar";
import { useWorkspaces } from "hooks/useWorkspaces";
import { ROLE_PERMISSIONS } from "constants/rbac";
import { FONT_SIZE, COLOR, BORDER_RADIUS } from "constants/styles";

interface Props {
  webhook?: WebhookDefinition;
  onUpdate?: (data: WebhookFormData) => void;
  onAdd?: (id: string) => void;
  onCancel?: () => void;
}

export const WebhookForm = ({ webhook, onAdd, onUpdate, onCancel }: Props) => {
  const { selectedWorkspace } = useWorkspaces();

  const transformedConditions = webhook
    ? transformConditionsToFormFormat(webhook)
    : undefined;

  const methods = useForm<WebhookFormDataRaw>({
    mode: "onChange",
    defaultValues: getWebhookFormDefaultValues(webhook),
  });
  const { register, handleSubmit, control, watch, setValue, formState } =
    methods;
  const watchStatus = watch("status");
  const { errors } = formState;

  const workspaceId = webhook ? webhook.workspace_id : selectedWorkspace?.id;
  const workspaceName = webhook
    ? webhook.workspace_name
    : selectedWorkspace?.name;

  const onSubmit = async (
    existingId: string | undefined,
    data: WebhookFormDataRaw
  ) => {
    const transformedFormData = transformWebhookFormDataToBackendFormat(data);
    try {
      if (existingId) {
        await updateWorkspaceWebhook(existingId, transformedFormData);
        toast.success("Webhook updated!");
        onUpdate?.(transformedFormData);
      } else {
        const res = await addWorkspaceWebhook(transformedFormData);
        toast.success("Webhook created!");
        onAdd?.(res.id);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // show an error returned by API route (JSON object with error prop)
        const backendResponse = error.response?.data as { error?: string };
        toast.error(backendResponse.error ?? error.message);
      }
      console.error(error);
    }
  };

  const permissionToSave = webhook
    ? !!ROLE_PERMISSIONS.projects.update[webhook.role_name]
    : true;

  return (
    <Card>
      <style jsx>{`
        .b-form-item {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
        }

        .b-form-item:first-child {
          margin-top: 0px;
        }

        .error {
          display: flex;
          margin-top: 2px;
          color: ${COLOR.DANGER};
        }
        .remove-btn-wrapper {
          position: absolute;
          display: flex;
          align-content: center;
          font-size: ${FONT_SIZE.SECONDARY};
          cursor: pointer;
          color: ${COLOR.DANGER};
          padding: 3px;
          top: 16px;
          right: 16px;
        }
        .pos-rel {
          position: relative;
        }
        .flex-column {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .flex-column + .flex-column {
          margin-left: 24px;
        }
        .cols-wrapper {
          display: flex;
          width: 100%;
        }
        .full-width {
          width: 100%;
        }

        .stick-bottom {
          display: flex;
          height: 100%;
          align-items: end;
        }

        .b-actions {
          display: flex;
          justify-content: flex-end;
        }

        .workspace-wrapper {
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          border-radius: ${BORDER_RADIUS.SM};
          padding: 6px 10px;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
          background: ${COLOR.BG_DISABLED};
          color: ${COLOR.TEXT_PRIMARY};
          cursor: default;
        }

        .workspace-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 400;
        }
      `}</style>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(async data => {
            if (!workspaceId) {
              throw Error("Workspace not selected");
            }
            return onSubmit(webhook?.id, {
              ...data,
              workspace_id: workspaceId,
            });
          })}
          className="wasValidated full-width"
        >
          <div className="cols-wrapper">
            {/* Webhook name */}
            <div className="flex-column">
              <div className="b-form-item">
                <label htmlFor="name">Webhook name</label>
                <input
                  autoComplete="off"
                  className={
                    errors?.name ? "is-invalid form-control" : "form-control"
                  }
                  {...register("name", {
                    required: ERROR.FIELD_REQUIRED,
                  })}
                />
                {errors?.name && (
                  <span className="error">{errors.name.message}</span>
                )}
              </div>

              {/* Description */}
              {/* <Row className="mt-3">
                  <Col lg={12} md={12} sm={12}>
                    <label htmlFor="description">Description</label>
                    <input
                      placeholder="Optional description"
                      autoComplete="off"
                      className={
                        errors?.description
                          ? "is-invalid form-control"
                          : "form-control"
                      }
                      name="description"
                      ref={register()}
                    />
                  </Col>
                </Row> */}

              {/* Endpoint URL */}
              <div className="b-form-item">
                <label htmlFor="url">Endpoint URL</label>
                <input
                  placeholder="https://example.com"
                  autoComplete="off"
                  className={
                    errors?.url ? "is-invalid form-control" : "form-control"
                  }
                  {...register("url", {
                    required: ERROR.FIELD_REQUIRED,
                    validate: val =>
                      validator.isURL(val, { require_protocol: true }),
                  })}
                />
                {errors?.url && (
                  <span className="error">
                    {errors.url.type === "validate"
                      ? ERROR.INVALID_URL_FORMAT
                      : errors.url.message}
                  </span>
                )}
              </div>

              {/* Network (mainnet/testnet) */}
              <div className="b-form-item">
                <label htmlFor="network">Network</label>
                <Controller
                  control={control}
                  name="network"
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={WEBHOOKS_NETWORKS[0]}
                      styles={{
                        menu: provided => ({ ...provided, zIndex: 5 }),
                      }}
                      options={WEBHOOKS_NETWORKS.filter(
                        n => n.name !== "cardano-testnet" // cardano-testnet is disabled for new webhooks
                      )}
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {/* https://github.com/react-hook-form/react-hook-form/issues/987 */}
                  {(errors?.network as unknown as FieldError)?.message}
                </div>
              </div>

              {/* Workspace */}
              <div className="b-form-item">
                <label>Workspace</label>
                {workspaceId && (
                  <div className="workspace-wrapper">
                    <WorkspaceAvatar
                      workspaceId={workspaceId}
                      size={23}
                      style={{ marginRight: "1ch" }}
                    />
                    <div className="workspace-name">{workspaceName}</div>
                  </div>
                )}
              </div>

              {/* Status  */}
              <div className="b-form-item" data-testid="webhook-switch">
                <label htmlFor="status" data-testid="webhook-switch-label">
                  Status
                </label>

                <Switch
                  // type="switch"
                  id="status"
                  {...register("status")}
                  defaultChecked={watchStatus}
                  label={watchStatus ? "Enabled" : "Disabled"}
                />
              </div>

              {/* Trigger type (block/transaction/delegation) */}
              <div className="b-form-item">
                <label htmlFor="trigger">Trigger</label>
                <Controller
                  control={control}
                  name="trigger"
                  render={({ field }) => (
                    <Select
                      defaultValue={TRIGGER_OPTIONS[0]}
                      {...field}
                      options={TRIGGER_OPTIONS}
                      onChange={e => {
                        field.onChange(e);
                        setValue("conditions", []);
                      }}
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {(errors?.trigger as unknown as FieldError)?.message}
                </div>
              </div>

              {/*  Required confirmations */}
              <div className="b-form-item">
                <label htmlFor="confirmations">
                  <span>Required confirmations</span>
                  <Tippy
                    interactive
                    content={
                      <>
                        <p>
                          By default events are sent immediately as they occur
                          on the blockchain (0 confirmations). Sometimes, the
                          network rollbacks a few blocks, invalidating the event
                          that has been sent.
                        </p>
                        <p>
                          <span>
                            We recommend verifying that the event you received
                            has not been rolled back. Alternatively, you may
                            increase the number of required confirmations before
                            the event is sent to your endpoint.
                          </span>
                          <span className="text-tooltip">
                            <span> To learn more, see</span>{" "}
                            <a
                              target="_blank"
                              href={
                                "https://blockfrost.dev/docs/start-building/webhooks/using-webhooks#rollbacks-and-a-required-number-of-confirmations"
                              }
                              rel="noreferrer"
                            >
                              Blockfrost Developer Portal: Rollbacks
                            </a>
                            <span>.</span>
                          </span>
                        </p>
                      </>
                    }
                  >
                    <span style={{ cursor: "pointer" }}>
                      <IoHelpCircleOutline size={18} />
                    </span>
                  </Tippy>
                </label>
                <input
                  autoComplete="off"
                  className={
                    errors?.confirmations
                      ? "is-invalid form-control"
                      : "form-control"
                  }
                  type="number"
                  min={0}
                  max={5}
                  {...register("confirmations", {
                    validate: value => {
                      if (!validator.isNumeric(value.toString())) {
                        return ERROR.CONFIRMATIONS_OUT_OF_RANGE;
                      }
                      if (
                        !(
                          Number(value) >= 0 &&
                          Number(value) <= MAX_CONFIRMATIONS
                        )
                      )
                        return ERROR.CONFIRMATIONS_OUT_OF_RANGE;
                    },
                  })}
                />
                {errors?.confirmations && (
                  <span className="error">{errors.confirmations.message}</span>
                )}
              </div>
            </div>

            {/* Identifier and auth token information */}
            <div className="flex-column">
              {webhook && <WebhookIdentifierToken webhook={webhook} />}
              <div className="stick-bottom">
                <ShinyBanner
                  title="Build Your Next Webhook"
                  subtitle="Get started with Secure Webhooks Documentation"
                  ctaLabel="See docs"
                  ctaHref="https://blockfrost.dev/docs/start-building/webhooks/"
                />
              </div>
            </div>
          </div>

          {/* Conditions */}
          <hr />
          <div className="b-form-item">
            <label htmlFor="triggerConditions">Trigger conditions</label>
            <ConditionsEditor conditions={transformedConditions} />
          </div>

          {/* Action buttons */}
          <div className="b-actions">
            <Button uppercase variant="secondary-alt" onClick={onCancel}>
              Close
            </Button>
            <Button
              type="submit"
              variant="success"
              data-testid="save-webhook"
              disabled={
                !formState.isValid ||
                formState.isSubmitting ||
                !permissionToSave
              }
              loading={formState.isSubmitting}
              uppercase
              style={{ marginLeft: "5px" }}
            >
              Save Webhook
            </Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
