import React from "react";
import Skeleton from "react-loading-skeleton";

const NavbarDropdownSkeleton = () => {
  return (
    <Skeleton width={170} height={24} data-testid="navbar-dropdown-skeleton" />
  );
};

export { NavbarDropdownSkeleton };
