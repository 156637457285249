import React, { useState } from "react";
import { signOut } from "next-auth/react";
import { useWorkspaces } from "hooks/useWorkspaces";
import { Dropdown } from "components/UI/Dropdown";
import { routes } from "utils/routes";
import WorkspaceAvatar from "components/workspaces/WorkspaceAvatar";
import Link from "next/link";
import { FaExternalLinkAlt } from "react-icons/fa";
import { NavbarDropdownSkeleton } from "./NavbarDropdownSkeleton";
import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import { FiSettings } from "react-icons/fi";

interface Props {
  userEmail: string;
}

const NavbarDropdown = ({ userEmail }: Props) => {
  const {
    workspaces,
    isWorkspacesLoading,
    isWorkspacesError,
    selectedWorkspace,
    setSelectedWorkspace,
  } = useWorkspaces();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="workspace-picker-wrapper">
      <div className="workspace-picker">
        {(isWorkspacesLoading || isWorkspacesError || !selectedWorkspace) && (
          <NavbarDropdownSkeleton />
        )}
        {workspaces && selectedWorkspace && (
          <Dropdown
            maxHeight="400px"
            toggler={
              <div
                className="dropdown-workspace-toggler"
                data-testid="dropdown-workspace-toggler"
                onClick={() => {
                  setIsDropdownOpen(prev => !prev);
                }}
                style={{ cursor: "pointer" }}
              >
                <WorkspaceAvatar
                  workspaceId={selectedWorkspace.id}
                  size={23}
                  style={{ marginRight: "1ch" }}
                />
                <div className="workspace-name">{selectedWorkspace.name}</div>
              </div>
            }
            open={isDropdownOpen}
            onOutsideClick={() => setIsDropdownOpen(false)}
            menu={[
              {
                header: "Workspaces",
              },
              ...workspaces.map(workspace => ({
                onClick: () => {
                  setSelectedWorkspace(workspace.id);
                },
                label: (
                  <Link href={routes.dashboard} passHref>
                    <div
                      className="dropdown-workspace-item-label"
                      onClick={() => {
                        setIsDropdownOpen(prev => !prev);
                      }}
                      style={{ cursor: "pointer" }}
                      data-testid={`workspace-item`}
                    >
                      <div className="dropdown-icon-wrapper">
                        <WorkspaceAvatar workspaceId={workspace.id} size={23} />
                      </div>
                      <div className="workspace-name" title={workspace.name}>
                        {workspace.name}
                      </div>
                      <div className="workspace-settings-wrapper">
                        <Link
                          href={routes.workspace(selectedWorkspace.id)}
                          className="no-style"
                        >
                          <FiSettings size={16} title="Manage Workspace" />
                        </Link>
                      </div>
                    </div>
                  </Link>
                ),
                noStyle: true,
                nonActive: false,
              })),
              {
                header: (
                  <div
                    title={`Logged as ${userEmail}`}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      marginTop: "4px",
                      maxWidth: "190px", // hacky, but 220-additional padding added in header component
                    }}
                  >
                    {userEmail}
                  </div>
                ),
              },
              // { href: routes.dashboard, label: "Dashboard" },

              {
                href: routes.dashboardSubscription,
                label: "My Subscription",
                dataCy: "my-subscription",
              },
              { href: routes.workspaces, label: "Manage Workspaces" },
              {
                href: routes.billingPortal,
                label: (
                  <>
                    <span>Manage Billing</span>
                    <div className="icon-wrapper">
                      <FaExternalLinkAlt size={10} />
                    </div>
                  </>
                ),
              },
              {
                href: routes.settings,
                label: "Settings",
                dataCy: "menu-settings",
              },
              {
                onClick: async () => {
                  await signOut({ callbackUrl: routes.authSignIn });
                },
                label: "Sign Out",
                dataCy: "sign-out-button",
              },
            ]}
          />
        )}
      </div>
      <style jsx>{`
        .workspace-picker-wrapper {
          display: flex;
          align-items: flex-end;
        }

        .dropdown-label {
          /* display: block; */
          margin: 8px 0px;
          width: 320px;
        }

        .manage-workspaces {
          display: flex;
          font-size: ${FONT_SIZE.PRIMARY};
          font-weight: 500;
          align-items: center;
        }

        .dropdown-icon-wrapper {
          margin-right: 8px;
          width: 24px;
          display: flex;
          justify-content: center;
        }

        .dropdown-workspace-toggler,
        .dropdown-workspace-item-label {
          display: flex;
          align-items: center;
          font-size: ${FONT_SIZE.PRIMARY};
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          transition: all 0.2s ease-in-out;
          cursor: pointer;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          padding: 8px 16px;
          max-width: 220px;
          overflow: hidden;
        }

        .dropdown-workspace-item-label {
          width: 100%;
          max-width: 220px;
          font-weight: 500;
        }

        .workspace-settings-wrapper {
          display: none;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          padding: 4px 8px;
          margin-top: -4px;
          margin-bottom: -4px;
          margin-right: -8px;
          transition: all 0.3s ease-in-out;
        }

        .workspace-settings-wrapper:hover {
          background: ${COLOR.BORDER_PRIMARY};
        }

        .dropdown-workspace-item-label:hover {
          .workspace-settings-wrapper {
            display: flex;
          }
        }

        .dropdown-workspace-toggler:hover,
        .dropdown-workspace-item-label:hover {
          background: ${COLOR.BG_PRIMARY};
        }

        .dropdown-workspace-toggler .workspace-name {
          font-weight: 500;
        }

        .workspace-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
        }

        .icon-wrapper {
          display: inline-flex;
          align-items: center;
          margin-left: 1ch;
        }
      `}</style>
    </div>
  );
};

export default NavbarDropdown;
