import React, { useState } from "react";
import { Badge, Button } from "components";
import Link from "next/link";
import { Plus } from "react-feather";
import { routes } from "utils/routes";
import Tippy from "@tippyjs/react";
import { COLOR, FONT_SIZE } from "constants/styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  heading: React.ReactNode;
  resource: "webhook" | "project";
  remainingQuota: React.ReactNode;
  reachedSubscriptionLimit: boolean;
  ctaButton?: {
    label: React.ReactNode;
    onClick: () => Promise<unknown> | void;
    isDisabled?: boolean;
    "data-testid": string;
  };
  workspaceId: string;
  isUserSubscription: boolean;
  insufficientPermissions: boolean;
  betaBadge?: boolean;
}

export const ProjectListHeader = ({
  heading,
  resource,
  ctaButton,
  remainingQuota,
  reachedSubscriptionLimit,
  betaBadge,
  isUserSubscription,
  insufficientPermissions,
  workspaceId,
  ...rest
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const getDisabledReason = (
    isDisabled: boolean,
    reachedSubscriptionLimit: boolean,
    insufficientPermissions: boolean,
    isUserSubscription: boolean
  ) => {
    if (!isDisabled) return null;

    if (insufficientPermissions) {
      return `You don't have permission to create a new ${resource} in this workspace.`;
    }

    if (reachedSubscriptionLimit) {
      if (isUserSubscription) {
        return (
          <>
            <Link href={routes.dashboardSubscription} passHref legacyBehavior>
              <a className="link">Upgrade your plan</a>
            </Link>
            &nbsp;
            <span>to get more&nbsp;</span>
            {resource}
            <span>s</span>
            <style jsx>{`
              .link {
                color: ${COLOR.TEXT_LIGHT};
                font-weight: bold;
                text-decoration: underline;
              }
            `}</style>
          </>
        );
      } else {
        return (
          <>
            <div>
              <span>Subscription used by&nbsp;</span>
              <Link
                href={routes.workspace(workspaceId)}
                passHref
                legacyBehavior
              >
                <a className="link">the workspace</a>
              </Link>
              <span> has reached the allocated quota.&nbsp;</span>
            </div>

            <style jsx>{`
              .link {
                color: ${COLOR.TEXT_LIGHT};
                font-weight: bold;
                text-decoration: underline;
              }
            `}</style>
          </>
        );
      }
    }
  };

  const disabledReason = getDisabledReason(
    ctaButton?.isDisabled ?? false,
    reachedSubscriptionLimit,
    insufficientPermissions,
    isUserSubscription
  );

  return (
    <>
      <style jsx>{`
        .link {
          color: ${COLOR.TEXT_LIGHT};
          font-weight: bold;
          text-decoration: underline;
        }
        .link-text {
          padding-left: 3px;
        }

        .section-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 50px 0 15px 0;
          /* padding-top: 30px; */
          align-items: flex-end;
        }
        .section-heading h4 {
          margin-bottom: 0;
        }
        .button-wrapper {
          display: flex;
          flex-direction: column;
        }
        .quota-remaining {
          font-size: ${FONT_SIZE.SECONDARY};
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
          margin: 10px 0 0 0;
          text-transform: uppercase;
        }

        .badge-wrapper {
          display: flex;
          align-items: center;
        }
      `}</style>

      <div className="section-heading" {...rest}>
        <div className="badge-wrapper">
          <h4>{heading}</h4>
          {betaBadge && <Badge>Beta</Badge>}
        </div>
        <div className="button-wrapper">
          {ctaButton && (
            <Tippy
              disabled={!disabledReason}
              interactive={true}
              content={
                <div data-testid="disabled-reason-tooltip">
                  {disabledReason}
                </div>
              }
            >
              <div>
                <Button
                  onClick={async () => {
                    setSubmitting(true);
                    try {
                      await ctaButton.onClick();
                    } catch (error) {
                      setSubmitting(false);
                      throw error;
                    }
                    setSubmitting(false);
                  }}
                  size="sm"
                  uppercase
                  data-testid={ctaButton["data-testid"]}
                  disabled={ctaButton.isDisabled || submitting}
                  loading={submitting}
                  icon={<Plus size={14} />}
                >
                  {ctaButton.label}
                </Button>
              </div>
            </Tippy>
          )}

          <h5 className="quota-remaining">{remainingQuota}</h5>
        </div>
      </div>
    </>
  );
};
