import React, { useEffect, useState } from "react";
import { Button } from "components/UI/Button";
import { Modal } from "components/UI/Modal";
import { Switch } from "components/UI/Switch";
import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import { updateSettings, useSettings } from "hooks/useSettings";
import { toast } from "react-toastify";

interface Props {}
const MarketingConsentModal = ({}: Props) => {
  const [consents, setConsents] = useState({
    marketingAnalysis: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { settings } = useSettings();

  useEffect(() => {
    if (settings === null || settings?.marketing_consent === null) {
      // settings loaded and consent not set yet
      setIsOpen(true);
    }
  }, [settings]);

  const handleConsentChange = (key: "marketingAnalysis", value: boolean) => {
    setConsents(prev => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateSettings({ marketing_consent: consents.marketingAnalysis });
      toast.success("Your preferences have been successfully updated.");
    } catch (error) {
      console.error("Error saving marketing consent", error);
      toast.error(
        "Your preferences could not be saved. If the issue persists, please contact Customer Support."
      );
    }
    setIsSubmitting(false);
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        heading="Marketing Preferences"
        description="Use the toggle below to choose your marketing preferences and usage of personalized insights. This will helps us improve our products and services. You can update your preferences anytime in settings."
        actions={
          <>
            <Button
              style={{ marginLeft: "8px" }}
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit}
              uppercase
              data-testid="save-preferences"
            >
              Save Preferences
            </Button>
          </>
        }
        data-testid="marketing-consent-modal"
        style={{ maxWidth: BREAKPOINT.SM }}
      >
        <div className="item-wrapper">
          <div className="item">
            <div className="item-title">Marketing Insights</div>
            <div className="item-description">
              I consent to the use of my information for marketing insights.
            </div>
          </div>

          <div className="item-control">
            <Switch
              id="marketingAnalysis"
              defaultChecked={consents.marketingAnalysis}
              onChange={e =>
                handleConsentChange("marketingAnalysis", e.target.checked)
              }
            />
          </div>
        </div>
      </Modal>
      <style jsx>
        {`
          .item {
            display: flex;
            flex-direction: column;
            max-width: 70%;
          }

          .item-title {
            font-size: ${FONT_SIZE.PRIMARY};
            font-weight: 400;
            color: ${COLOR.TEXT_PRIMARY};
          }

          .item-description {
            display: flex;
            font-size: ${FONT_SIZE.SECONDARY};
            color: ${COLOR.TEXT_SECONDARY};
          }

          .item-control {
            display: flex;
            align-items: center;
          }

          .item-wrapper {
            display: flex;
            justify-content: space-between;
            margin: 24px 12px;
          }

          .item-wrapper + .item-wrapper {
            margin-top: 24px;
          }
        `}
      </style>
    </>
  );
};

export default MarketingConsentModal;
