import React from "react";
import Link from "next/link";
import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import { IoChevronForwardOutline } from "react-icons/io5";

export interface BreadcrumbItem {
  href?: string | (() => string);
  label: React.ReactNode;
  active?: boolean;
  interactive?: boolean;
}
interface Props {
  items: BreadcrumbItem[];
}

export const Breadcrumbs = ({ items }: Props) => {
  return (
    <>
      <nav aria-label="b-breadcrumb">
        <ol className="b-breadcrumb" style={{ marginBottom: "16px" }}>
          {items.map((item, index) => {
            const href =
              typeof item.href === "function" ? item.href() : item.href;

            const key = href ?? index;
            return (
              <li
                key={key}
                style={
                  item.interactive === false
                    ? { pointerEvents: "none" }
                    : undefined
                }
                className={`b-breadcrumb-item ${
                  item.active ? "b-breadcrumb-item-active" : ""
                }`}
              >
                {index !== 0 && (
                  <div className="separator">
                    <IoChevronForwardOutline size={12} />
                  </div>
                )}
                {item.active ? (
                  <div className={`link link-active`}>{item.label}</div>
                ) : (
                  <Link href={href ?? "#"} passHref legacyBehavior>
                    <a className="link" data-testid="breadcrumb-link">
                      {item.label}
                    </a>
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>

      <style jsx>{`
        .b-breadcrumb {
          display: flex;
          flex-wrap: wrap;
          padding: 6px 8px;
          margin-bottom: 1rem;
          list-style: none;
          border-radius: 0;
          background-color: transparent;
          padding: 0;
        }

        .b-breadcrumb-item {
          display: flex;
          align-items: center;
          font-size: ${FONT_SIZE.XXS};
          font-weight: 600;
          letter-spacing: 0.1ch;
          height: 29px;
          text-transform: uppercase;
        }

        .link {
          padding: 6px 8px;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          /* border: 1px solid ${COLOR.BG_PRIMARY}; */
          transition: all 0.2s ease-in-out;
        }

        .link:hover,
        .link-active {
          background-color: ${COLOR.BG_PRIMARY};
          color: ${COLOR.TEXT_PRIMARY};
        }

        .b-breadcrumb-item + .b-breadcrumb-item {
          padding-left: 0.5rem;
        }

        .separator {
          display: inline-block;
          padding-right: 0.5rem;
          color: ${COLOR.TEXT_PRIMARY};
        }

        .b-breadcrumb-item > :global(a) {
          color: ${COLOR.TEXT_PRIMARY};
        }
      `}</style>
    </>
  );
};
