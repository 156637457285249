import React from "react";
import { useForm, Controller } from "react-hook-form";
import { SORTED_NETWORKS } from "constants/networks";
import Select from "react-select";
import { Button, Card } from "components";
import { BORDER_RADIUS, BREAKPOINT, COLOR } from "constants/styles";
import WorkspaceAvatar from "components/workspaces/WorkspaceAvatar";
import { useWorkspaces } from "hooks/useWorkspaces";

interface Props {
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (data: { [x: string]: any }) => void;
}

function AddProjectForm({ onCancel, onSave }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { selectedWorkspace } = useWorkspaces();

  return (
    <Card title="Create a new project">
      <style jsx>{`
        .form-content {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr;
          gap: 32px;
        }

        .b-col {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
        }

        .actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          white-space: nowrap;
          flex: 1 1 auto;
        }

        .workspace-wrapper {
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          max-width: 220px;
          border-radius: ${BORDER_RADIUS.SM};
          padding: 6px 10px;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
          background: ${COLOR.BG_DISABLED};
          color: ${COLOR.TEXT_PRIMARY};
          cursor: default;
        }

        .workspace-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 400;
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .workspace-wrapper {
            max-width: 100%;
          }

          .form-content {
            grid-template-columns: 1fr;
            gap: 16px;
          }
        }
      `}</style>
      <form
        onSubmit={handleSubmit(async data => onSave(data))}
        className="wasValidated"
      >
        <div className="form-content">
          <div className="b-col">
            <label htmlFor="projectName">Project name</label>
            <input
              autoComplete="on"
              className={
                errors?.projectName ? "is-invalid form-control" : "form-control"
              }
              {...register("projectName", {
                required: "Project name is empty",
              })}
            />
          </div>
          <div className="b-col">
            <label htmlFor="networkId">Network</label>
            <Controller
              control={control}
              name="network"
              defaultValue={SORTED_NETWORKS[0]}
              render={({ field }) => (
                <Select
                  {...field}
                  options={SORTED_NETWORKS.filter(
                    n => n.name !== "cardano-testnet"
                  )} // creating new cardano-testnet projects is disabled
                  classNamePrefix="network-select"
                />
              )}
            />
            <div className="invalid-feedback">
              {errors?.network?.message as string}
            </div>
          </div>
          <div className="b-col">
            <label htmlFor="networkId">Workspace</label>
            {selectedWorkspace && (
              <div className="workspace-wrapper">
                <WorkspaceAvatar
                  workspaceId={selectedWorkspace?.id}
                  size={23}
                  style={{ marginRight: "1ch" }}
                />
                <div className="workspace-name">{selectedWorkspace.name}</div>
              </div>
            )}
          </div>
          <div className="actions">
            <Button uppercase variant="secondary-alt" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="success"
              uppercase
              data-testid="save-project"
              style={{ marginLeft: "5px" }}
            >
              Save Project
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
}

export { AddProjectForm };
