import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import Link from "next/link";
import React, { useLayoutEffect, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

interface MenuItem {
  label: React.ReactNode;
  href?: string;
  onClick?: () => void;
  nonActive?: boolean;
  noStyle?: boolean;
  dataCy?: string;
}

interface GroupHeader {
  header: React.ReactNode;
}

interface Props {
  toggler: React.ReactNode;
  open: boolean;
  onOutsideClick?: () => void;
  menu: (MenuItem | GroupHeader)[];
  maxHeight?: string;
  align?: "start" | "end";
  togglerWidth?: number;
}
export const Dropdown = ({
  open,
  toggler,
  menu,
  maxHeight,
  align = "start",
  togglerWidth = 0,
  onOutsideClick,
}: Props) => {
  const [menuWidth, setMenuWidth] = useState(0);
  const [overflowWidth, setOverflowWidth] = useState(0);
  const menuRef = React.createRef<HTMLUListElement>();
  const dropdownRef = React.createRef<HTMLDivElement>();

  useOnClickOutside(dropdownRef, () => {
    onOutsideClick?.();
  });

  useLayoutEffect(() => {
    // Keep the dropdown menu within the viewport.
    // Note: Currently mitigates overflow to the right of the viewport.
    if (!open) {
      // reset overflow after the dropdown menu was closed
      setOverflowWidth(0);
      setMenuWidth(0);
    } else if (menuRef.current && typeof window !== undefined) {
      const rect = menuRef.current.getBoundingClientRect();
      setMenuWidth(rect.width);
      if (rect.right > window.innerWidth) {
        // out of viewport
        const offset = 16;
        const overflow = rect.right - window.innerWidth + offset;
        setOverflowWidth(overflow);
      }
    }
  }, [menuRef, open]);

  const menuLeftOffset =
    align === "start" ? overflowWidth : menuWidth - togglerWidth;

  return (
    <>
      <style jsx>{`
        .b-dropdown {
          position: relative;
          /* z-index: 42; */
        }

        .b-menu {
          position: absolute;
          left: -${menuLeftOffset}px;

          list-style-type: none;
          margin: 5px 0;
          padding: 0;
          border: 1px solid rgba(187, 196, 208, 0.3);
          box-shadow: 0 0 8px 2px rgb(28 39 60 / 6%);
          padding: 5px;

          z-index: 42;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          /* width: 150px; */

          overflow-x: hidden;
          max-height: ${maxHeight ?? "none"};
          background-color: ${COLOR.BG_WHITE};
        }

        .b-menu > li {
          margin: 0;
          border-radius: ${BORDER_RADIUS.SM};
          background-color: ${COLOR.BG_WHITE};
          cursor: pointer;
        }

        .b-menu > li:hover {
          /* background-color: lightgray; */
        }

        .b-menu-item-link {
          border-radius: ${BORDER_RADIUS.PRIMARY};
          transition: all 0.2s ease-in-out;
          display: flex;
          width: 100%;
          font-weight: 400;
          color: ${COLOR.TEXT_PRIMARY};
          white-space: nowrap;
          padding: 6px 15px;
        }

        .b-menu-item-label {
          padding: 6px 15px;
          display: block;
          width: 100%;
          white-space: nowrap;
        }

        .b-menu-item-link:hover,
        .b-menu-item:focus > a,
        .b-menu-item-label:hover,
        .b-menu-item-label:focus {
          background-color: ${COLOR.BG_PRIMARY};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          transition: all 0.1s ease-in-out;
        }

        .non-active {
          pointer-events: none;
          cursor: default;
        }

        /* .b-backdrop {
          background-color: transparent;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 40;
        } */

        .dropdown-group-header {
          font-size: ${FONT_SIZE.SECONDARY};
          letter-spacing: 0.75px;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
        }
      `}</style>
      {/* useOnClickOutside hook is used instead of backdrop div */}
      {/* {onOutsideClick && open && (
        <div className="b-backdrop" onClick={onOutsideClick} />
      )} */}
      <div className="b-dropdown" ref={dropdownRef}>
        {toggler}
        {open ? (
          <ul className="b-menu" ref={menuRef}>
            {menu.map((menuItem, index) => {
              if ("header" in menuItem) {
                // render GroupHeader
                return (
                  <li key={index} className={`b-menu-item non-active `}>
                    <div className="b-menu-item-label dropdown-group-header">
                      {menuItem.header}
                    </div>
                  </li>
                );
              } else {
                // render MenuItem
                return (
                  <li
                    key={index}
                    className={`b-menu-item ${
                      menuItem.nonActive ? "non-active" : ""
                    }`}
                    onClick={() => {
                      menuItem.onClick?.();
                      onOutsideClick?.();
                    }}
                    data-testid={menuItem.dataCy}
                  >
                    {menuItem.href ? (
                      <Link href={menuItem.href} passHref legacyBehavior>
                        <a className="b-menu-item-link">{menuItem.label}</a>
                      </Link>
                    ) : (
                      <div
                        className={`${
                          menuItem.noStyle ? "" : "b-menu-item-label"
                        }`}
                      >
                        {menuItem.label}
                      </div>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        ) : null}
      </div>
    </>
  );
};
