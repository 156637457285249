import axios, { AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import useSWR, { mutate } from "swr";
import { SettingsSchema } from "types/settings";

const key = "/api/customer/settings";

export const useSettings = () => {
  const { data: session } = useSession();

  const { data, error } = useSWR<AxiosResponse<SettingsSchema | null>>(
    session ? key : null,
    axios.get
  );

  return {
    // @ts-expect-error API returns '' instead of null
    settings: data?.data === "" ? null : data?.data,
    isSettingsLoading: !error && !data,
    isSettingsError: error,
  };
};

export const updateSettings = async (
  updateObject: Partial<Omit<SettingsSchema, "id">>
) => {
  const res = await axios.put<SettingsSchema>(key, updateObject);
  mutate(key);
  return res.data;
};
