import React from "react";
import { Alert } from "components";
import { routes } from "utils/routes";
import { WorkspaceByHash } from "types/workspaces";
import { useWorkspaceAddons } from "hooks/useWorkspaceAddons";
import { format, formatRelative } from "date-fns";
import { ROLE_PERMISSIONS } from "constants/rbac";

interface Props {
  workspace: WorkspaceByHash;
}
export const RequestsBoostActive = ({ workspace }: Props) => {
  const { addons } = useWorkspaceAddons(workspace.id);

  const requestsBoostActive = addons?.find(
    a => a.type === "REQUESTS_BOOST_1M" || a.type === "REQUESTS_BOOST_300K"
  );
  const permissionToManageBilling = workspace
    ? !!ROLE_PERMISSIONS.workspace.manage_billing[workspace.role_name]
    : false;

  return (
    <>
      {requestsBoostActive ? (
        <Alert
          variant="info"
          cta={
            // If the user is owner of the workspace we can redirect him to the subscription page.
            // Non-owner is redirected to the workspace page where he can buy boost (admin role)
            workspace.is_creator
              ? {
                  children: "Manage subscription",
                  href: routes.dashboardSubscription,
                }
              : {
                  children: "Manage workspace",
                  href: routes.workspace(workspace.id),
                }
          }
          data-testid="requests-boost-active-notification"
        >
          <span>Subscription for workspace&nbsp;</span>
          <strong>{workspace?.name}</strong>
          <span> currently has an increased limit for daily requests.</span>
          {requestsBoostActive.expires_at && (
            <>
              <br />
              <span>
                This boost is temporary and will expire{" "}
                {formatRelative(
                  new Date(requestsBoostActive.expires_at),
                  new Date()
                )}{" "}
                ({format(new Date(requestsBoostActive.expires_at), "O")}).
              </span>
              {permissionToManageBilling && (
                <span>
                  {" "}
                  To permanently increase the limits, please upgrade the
                  subscription.
                </span>
              )}
            </>
          )}
        </Alert>
      ) : undefined}
    </>
  );
};

export default RequestsBoostActive;
